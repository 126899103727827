<template>
  <v-row>
    <v-col cols="12" md="3" v-for="(item, i) in items" :key="i">
      <v-card class="mx-auto" outlined>
        <v-list-item three-line>
          <v-list-item-avatar tile size="80">
            <v-img
              :src="item.src"
              cover
              width="92"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content align="end">
            <div class="text-overline mb-4">{{ item.title }}</div>
            <v-list-item-title class="text-h4 mb-1">
              {{ item.value }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    items: [],
  }),
  methods: {
    getDashboard() {
      this.$api
        .get("dashboard", {
          headers: {
            Authorization: `Bearer ${this.$cookies.get("token")}`,
          },
        })
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getDashboard();
  },
};
</script>